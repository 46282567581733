import React from 'react'
import { SpacesView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Spaces'} />
}

const Spaces = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <SpacesView data={data} />
}

export const spacesQuery = graphql`
  query spacesQuery {
    contentfulCaseStudyGrid(page: { eq: "Spaces" }) {
      ...CaseStudyGrid
    }
  }
`

export default Spaces
